window.addEventListener("load", function(){
    window.cookieconsent.initialise({
        "palette": {
            "popup": {
                "background": "#005b7a"
            },
            "button": {
                "background": "#fff",
                "text": "#005b7a"
            }
        },
        "theme": "classic",
        "content": {
            "href": "/privacy"
        }
    })
});