$(function() {

    $(".work").on("click", function() {

        // give current box active class
        $(".work").removeClass("active");
        $(this).addClass("active");

        // get id of box clicked
        var section = this.id;
        // get name after dash (ie the ID of the div we want to show)
        var mydiv = section.split('-')[1];

        // hide/show as required
        $(".desc-visible").hide();
        $("#"+mydiv).show();

        // try to best fit the scroll to viewport size
        var window_width = $(window).width();

        if (window_width >= 992) {
            $("html,body").animate({
                //scrollTop: $("#ifa_list").offset().top
            }, 1000);
        } else {
            $("html,body").animate({
                //scrollTop: mydiv.offset().top
            }, 1000);
        }
    });

});