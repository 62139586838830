$(function() {

    var e = $("#ajax-contact");
    var c = $("#ajax-contact-charity");
    var t = $("#form-status");

    $(e).submit(function(a) {

        event.preventDefault();

        var form = $(this);

        var formMethod = $(this).attr('method');
        var formAction = $(this).attr('action');
        var formData = $(this).serialize();
        var submitBtn = $(".submit", form);
        var status = $("#form-status", form);

        // we need to pass the token through
        $.ajaxSetup({
            headers: {
                'X-XSRF-Token': $('meta[name="_token"]', form).attr('content')
            }
        });

        $.ajax({
            type  : formMethod,
            url   : formAction,
            data  : formData,
            cache : false,
            beforeSend : function() {

                // disable buttons whilst awaiting response
                submitBtn.prop("disabled", true);
                // reset data and class of alert
                status.html("").attr("class", "alert feedback").hide();

            },
            success : function(data) {

                // provide user with feedback
                status.html(data.message).show();

                // style accordingly
                if (data.success == true) {
                    status.addClass("alert-success");

                    setTimeout(function() {
                        status.html("").attr("class", "alert feedback").hide();
                        submitBtn.prop("disabled", false);
                        //$("#name, #email, #message").val("");
                    }, 3000);

                } else {
                    status.addClass("alert-danger");
                    submitBtn.prop("disabled", false);
                }

            },
            error: function (data) {
                // provide user with feedback
                status.html(data.message).show().addClass("alert-danger");
                submitBtn.prop("disabled", false)
            }
        });

        return false; // prevent form from sending
    });

    $(c).submit(function(a) {

        event.preventDefault();

        var form = $(this);

        var formMethod = $(this).attr('method');
        var formAction = $(this).attr('action');
        var formData = $(this).serialize();
        var submitBtn = $(".submit", form);
        var status = $("#form-status", form);

        // we need to pass the token through
        $.ajaxSetup({
            headers: {
                'X-XSRF-Token': $('meta[name="_token"]', form).attr('content')
            }
        });

        $.ajax({
            type  : formMethod,
            url   : formAction,
            data  : formData,
            cache : false,
            beforeSend : function() {

                // disable buttons whilst awaiting response
                submitBtn.prop("disabled", true);
                // reset data and class of alert
                status.html("").attr("class", "alert feedback").hide();

            },
            success : function(data) {

                // provide user with feedback
                status.html(data.message).show();

                // style accordingly
                if (data.success == true) {
                    status.addClass("alert-success");

                    setTimeout(function() {
                        status.html("").attr("class", "alert feedback").hide();
                        submitBtn.prop("disabled", false);
                        //$("#name, #email, #message").val("");
                    }, 3000);

                } else {
                    status.addClass("alert-danger");
                    submitBtn.prop("disabled", false);
                }

            },
            error: function (data) {
                // provide user with feedback
                status.html(data.message).show().addClass("alert-danger");
                submitBtn.prop("disabled", false)
            }
        });

        return false; // prevent form from sending
    });
});